import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBarsStaggered, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState, useContext } from "react";
import { NavContext } from "../../App";
import Topbar from "./topbar";
import { bgDarkColor, bgDarkGrayColor, bgWhiteColor, bgBlueColor, txtDarkColor, txtGrayColor, txtBlueColor, txtRedColor, hoverBGDarkColor, hoverBGDarkGrayColor, hoverBGWhiteGrayColor, hoverTxtBlueColor, hoverTxtRedColor, hoverTxtYellowColor, borderGrayColor, borderDarkColor, bgGrayColor } from "../../style/colors";
import IvaoEgLogo from "../ivaoEgLogo";
import DarkMode from "./darkMode";

const SideNavbar = (props) => {

    const {darkMode, setDarkMode} = props.data;
    const activeNav = useContext(NavContext).activeNav;
    const [navbarItems, setNavbarItems] = useState(false);
    
    // give dropMenu class display none in first render
    useEffect(() => {
        closeAllDropMenu();
    },[]);

    //close all navbar's dropmenu function
    function closeAllDropMenu(){
        const dropMenus = document.querySelectorAll(".dropMenu2");
        for(let i=0; i< dropMenus.length; i++){
            dropMenus[i].style.display = "none";
        }
    }

    // show-hide Navbar Items
    function handleMenuItems(){
        //show Nav items
        if(!navbarItems){
            document.querySelector(".showMenuBtn").style.display = "none";
            document.querySelector(".hideMenuBtn").style.display = "block";
            document.querySelector(".menuItems2").style.transform = "translateX(0)";
            setNavbarItems(true);
        }
        //hide Nav items
        else{
            document.querySelector(".hideMenuBtn").style.display = "none";
            document.querySelector(".showMenuBtn").style.display = "block";
            document.querySelector(".menuItems2").style.transform = "translateX(-224px)";
            setTimeout(() => {
                closeAllDropMenu();
            }, 100);
            setNavbarItems(false);
        }
    }

    // open-close dropmenus on click
    function handleDropMenuClick(x, click){
        const dropMenus = document.querySelectorAll(".dropMenu2");
        // show&hide Navbar drop menu on Click
        if(click){
            if(dropMenus[x-1].style.display === "none"){
                closeAllDropMenu();
                dropMenus[x-1].style.display = "block";
            }else{
                dropMenus[x-1].style.display = "none";
                handleMenuItems();
            }
        }
    }

    // scroll to top function when choosing a page
    function handleChoosenLink(){
        window.scrollTo({
            top:0,
            behavior: 'smooth',
        });
    }

    return(
        <>
            <div className={`${darkMode ? bgDarkColor : bgWhiteColor} sidenav-header md:hidden w-full z-50 sticky top-0 select-none transition duration-500 ease-in-out`}>
                <Topbar data={{darkMode}} />
                <div className="flex justify-center content-center">
                    <nav className="w-full flex flex-col p-4">
                        <div className="relative flex justify-center content-center">
                            <div className="logoContainer me-0">
                                <Link onClick={handleChoosenLink} to="/">
                                    <IvaoEgLogo data={{darkMode}} />
                                </Link>
                            </div>
                            <ul className="absolute left-0 top-0 bottom-0 flex justify-center flex-col content-center ms-1">
                                <li onClick={handleMenuItems} className={`showMenuBtn text-2xl cursor-pointer ${darkMode ? txtGrayColor : txtBlueColor} ${darkMode ? hoverTxtRedColor : hoverTxtYellowColor}`}><FontAwesomeIcon icon={faBars} /></li>
                                <li onClick={handleMenuItems} className={`hideMenuBtn hidden text-2xl cursor-pointer ${darkMode ? txtGrayColor : txtBlueColor} ${darkMode ? hoverTxtRedColor : hoverTxtYellowColor}`}><FontAwesomeIcon icon={faBarsStaggered} /></li>
                            </ul>
                            <li className="absolute right-0 top-0 bottom-0 flex justify-center flex-col content-center me-1">
                                <DarkMode data={{darkMode, setDarkMode}}/>
                            </li>
                        </div>
                    </nav>
                </div>
            </div>
            <div className={`${darkMode ? bgDarkColor : bgWhiteColor} menuItems2 md:hidden w-44 h-full z-40 top-0 left-0 fixed flex justify-center content-center pt-2 mt-20 transition -translate-x-56 duration-1000 ease-in-out`}>
                <ul className="flex flex-col items-center">
                    <li onClick={() => {setNavbarItems(false); handleMenuItems(); handleChoosenLink();}} className={`w-40 flex flex-col text-center ${darkMode ? txtGrayColor : txtDarkColor} ${darkMode ? bgDarkColor : bgWhiteColor } ${darkMode ? hoverBGDarkGrayColor : hoverBGWhiteGrayColor} border-b ${darkMode ? borderGrayColor : borderDarkColor}`}>
                        <Link className={`${activeNav === 'home' ? (darkMode ? txtRedColor+" "+bgDarkGrayColor : txtGrayColor+" "+bgBlueColor+" "+ hoverTxtYellowColor) : ''} ${darkMode ? hoverTxtRedColor : hoverTxtBlueColor} font-bold p-1.5`} to="/">Home</Link>
                    </li>
                    <li onClick={() => {handleDropMenuClick(1, true)}} className={`w-40 flex flex-col text-center ${darkMode ? txtGrayColor : txtDarkColor} ${darkMode ? bgDarkColor : bgWhiteColor } ${darkMode ? hoverBGDarkGrayColor : hoverBGWhiteGrayColor} border-b ${darkMode ? borderGrayColor : borderDarkColor}`}>
                        <Link className={`${activeNav === 'pilots' ? (darkMode ? txtRedColor+" "+bgDarkGrayColor : txtGrayColor+" "+bgBlueColor+" "+ hoverTxtYellowColor) : ''} ${darkMode ? hoverTxtRedColor : hoverTxtBlueColor} font-bold p-1.5`} to="#">Pilots<span className="ms-2.5 text-xs"><FontAwesomeIcon icon={faCaretDown} /></span></Link>
                        <ul className="relative cursor-pointer">
                            <div className={`${darkMode ? bgDarkGrayColor : bgGrayColor} ${txtGrayColor} dropMenu2 hidden w-full text-sm`}>
                                <Link onClick={handleChoosenLink} to="/pilot-training-exams">
                                    <li className={`block py-1.5 px-2 ${darkMode ? hoverTxtRedColor : hoverTxtBlueColor} ${darkMode ? hoverBGDarkColor : hoverBGWhiteGrayColor } border-b ${darkMode ? borderGrayColor : borderDarkColor}`}>Pilot Training/Exams</li>
                                </Link>
                                <Link onClick={handleChoosenLink} to="/pilot-software">
                                    <li className={`block py-1.5 px-2 ${darkMode ? hoverTxtRedColor : hoverTxtBlueColor} ${darkMode ? hoverBGDarkColor : hoverBGWhiteGrayColor } border-b ${darkMode ? borderGrayColor : borderDarkColor}`}>Pilot Software</li>
                                </Link>
                                <Link onClick={handleChoosenLink} to="/guidelines-ifr">
                                    <li className={`block py-1.5 px-2 ${darkMode ? hoverTxtRedColor : hoverTxtBlueColor} ${darkMode ? hoverBGDarkColor : hoverBGWhiteGrayColor } border-b ${darkMode ? borderGrayColor : borderDarkColor}`}>Guidelines IFR</li>
                                </Link>
                                <Link onClick={handleChoosenLink} to="/guidelines-vfr">
                                    <li className={`block py-1.5 px-2 ${darkMode ? hoverTxtRedColor : hoverTxtBlueColor} ${darkMode ? hoverBGDarkColor : hoverBGWhiteGrayColor } border-b ${darkMode ? borderGrayColor : borderDarkColor}`}>Guidelines VFR</li>
                                </Link>
                                <Link onClick={handleChoosenLink} to="https://wiki.ivao.aero/en/home/flightoperations/FAQ_VA" target="_blank">
                                    <li className={`block py-1.5 px-2 ${darkMode ? hoverTxtRedColor : hoverTxtBlueColor} ${darkMode ? hoverBGDarkColor : hoverBGWhiteGrayColor }`}>Virtual Airlines - FAQ</li>
                                </Link>
                                {/* <Link onClick={handleChoosenLink} to="/eg-training-docs">
                                    <li className={`block py-1.5 px-2 ${darkMode ? hoverTxtRedColor : hoverTxtBlueColor} ${darkMode ? hoverBGDarkColor : hoverBGWhiteGrayColor }`}>EG Documentation</li>
                                </Link> */}
                            </div>
                        </ul>
                    </li>
                    <li onClick={() => {handleDropMenuClick(2, "openClick")}} className={`w-40 flex flex-col text-center ${darkMode ? txtGrayColor : txtDarkColor} ${darkMode ? bgDarkColor : bgWhiteColor } ${darkMode ? hoverBGDarkGrayColor : hoverBGWhiteGrayColor} border-b ${darkMode ? borderGrayColor : borderDarkColor}`}>
                        <Link className={`${activeNav === 'atc' ? (darkMode ? txtRedColor+" "+bgDarkGrayColor : txtGrayColor+" "+bgBlueColor+" "+ hoverTxtYellowColor) : ''} ${darkMode ? hoverTxtRedColor : hoverTxtBlueColor} font-bold p-1.5`} to="#">ATC<span className="ms-2.5 text-xs"><FontAwesomeIcon icon={faCaretDown} /></span></Link>
                        <ul className="relative cursor-pointer">
                            <div className={`${darkMode ? bgDarkGrayColor : bgGrayColor} ${txtGrayColor} dropMenu2 hidden w-full text-sm`}>
                                <Link onClick={handleChoosenLink} to="/atc-training-exams">
                                    <li className={`block py-1.5 px-2 ${darkMode ? hoverTxtRedColor : hoverTxtBlueColor} ${darkMode ? hoverBGDarkColor : hoverBGWhiteGrayColor } border-b ${darkMode ? borderGrayColor : borderDarkColor}`}>ATC Training/Exams</li>
                                </Link> 
                                <Link onClick={handleChoosenLink} to="/atc-software">
                                    <li className={`block py-1.5 px-2 ${darkMode ? hoverTxtRedColor : hoverTxtBlueColor} ${darkMode ? hoverBGDarkColor : hoverBGWhiteGrayColor } border-b ${darkMode ? borderGrayColor : borderDarkColor}`}>ATC Software</li>
                                </Link> 
                                <Link onClick={handleChoosenLink} to="/atc-stations">
                                    <li className={`block py-1.5 px-2 ${darkMode ? hoverTxtRedColor : hoverTxtBlueColor} ${darkMode ? hoverBGDarkColor : hoverBGWhiteGrayColor } border-b ${darkMode ? borderGrayColor : borderDarkColor}`}>ATC Stations</li>
                                </Link>
                                <Link onClick={handleChoosenLink} to="/ssr-codes">
                                    <li className={`block py-1.5 px-2 ${darkMode ? hoverTxtRedColor : hoverTxtBlueColor} ${darkMode ? hoverBGDarkColor : hoverBGWhiteGrayColor } border-b ${darkMode ? borderGrayColor : borderDarkColor}`}>SSR Codes</li>
                                </Link>
                                <Link onClick={handleChoosenLink} to="/atc-documents">
                                    <li className={`block py-1.5 px-2 ${darkMode ? hoverTxtRedColor : hoverTxtBlueColor} ${darkMode ? hoverBGDarkColor : hoverBGWhiteGrayColor }`}>Documents</li>
                                </Link>
                            </div>
                        </ul>
                    </li>
                    <li onClick={() => {handleDropMenuClick(3, "openClick")}} className={`w-40 flex flex-col text-center ${darkMode ? txtGrayColor : txtDarkColor} ${darkMode ? bgDarkColor : bgWhiteColor } ${darkMode ? hoverBGDarkGrayColor : hoverBGWhiteGrayColor} border-b ${darkMode ? borderGrayColor : borderDarkColor}`}>
                        <Link className={`${activeNav === 'about' ? (darkMode ? txtRedColor+" "+bgDarkGrayColor : txtGrayColor+" "+bgBlueColor+" "+ hoverTxtYellowColor) : ''} ${darkMode ? hoverTxtRedColor : hoverTxtBlueColor} font-bold p-1.5`} to="#">About<span className="ms-2 text-xs"><FontAwesomeIcon icon={faCaretDown} /></span></Link>
                        <ul className="relative cursor-pointer">
                            <div className={`${darkMode ? bgDarkGrayColor : bgGrayColor} ${txtGrayColor} dropMenu2 hidden w-full text-sm`}>
                                <Link onClick={handleChoosenLink} to="https://wiki.ivao.aero/en/home/ivao/information" target="_blank">
                                    <li className={`block py-1.5 px-2 ${darkMode ? hoverTxtRedColor : hoverTxtBlueColor} ${darkMode ? hoverBGDarkColor : hoverBGWhiteGrayColor } border-b ${darkMode ? borderGrayColor : borderDarkColor}`}>Rules & Regulations</li>
                                </Link> 
                                <Link onClick={handleChoosenLink} to="/staff">
                                    <li className={`block py-1.5 px-2 ${darkMode ? hoverTxtRedColor : hoverTxtBlueColor} ${darkMode ? hoverBGDarkColor : hoverBGWhiteGrayColor }`}>EG Staff</li>
                                </Link>
                            </div>
                        </ul>
                    </li>
                    <li onClick={() => {handleDropMenuClick(4, "openClick")}} className={`w-40 flex flex-col text-center ${darkMode ? txtGrayColor : txtDarkColor} ${darkMode ? bgDarkColor : bgWhiteColor } ${darkMode ? hoverBGDarkGrayColor : hoverBGWhiteGrayColor} border-b ${darkMode ? borderGrayColor : borderDarkColor}`}>
                        <Link className={`${activeNav === 'resources' ? (darkMode ? txtRedColor+" "+bgDarkGrayColor : txtGrayColor+" "+bgBlueColor+" "+ hoverTxtYellowColor) : ''} ${darkMode ? hoverTxtRedColor : hoverTxtBlueColor} font-bold p-1.5`} to="#">Resources<span className="ms-2.5 text-xs"><FontAwesomeIcon icon={faCaretDown} /></span></Link>
                        <ul className="relative cursor-pointer">
                            <div className={`${darkMode ? bgDarkGrayColor : bgGrayColor} ${txtGrayColor} dropMenu2 hidden w-full text-sm`}>
                                <Link onClick={handleChoosenLink} to="https://eg.ivao.aero/download/data/EG_Division_procedure_V1.1.pdf">
                                    <li className={`block py-1.5 px-2 ${darkMode ? hoverTxtRedColor : hoverTxtBlueColor} ${darkMode ? hoverBGDarkColor : hoverBGWhiteGrayColor } border-b ${darkMode ? borderGrayColor : borderDarkColor}`}>Division Procedure</li>
                                </Link> 
                                <Link onClick={handleChoosenLink} to="/gca-requirements">
                                    <li className={`block py-1.5 px-2 ${darkMode ? hoverTxtRedColor : hoverTxtBlueColor} ${darkMode ? hoverBGDarkColor : hoverBGWhiteGrayColor } border-b ${darkMode ? borderGrayColor : borderDarkColor}`}>(GCA) Requirements</li>
                                </Link>
                                <Link onClick={handleChoosenLink} to="/charts">
                                    <li className={`block py-1.5 px-2 ${darkMode ? hoverTxtRedColor : hoverTxtBlueColor} ${darkMode ? hoverBGDarkColor : hoverBGWhiteGrayColor } border-b ${darkMode ? borderGrayColor : borderDarkColor}`}>Charts</li>
                                </Link>
                                <Link onClick={handleChoosenLink} to="https://eg.ivao.aero/download/data/EG_Domestic_Routes_2313.pdf" target="_blank">
                                    <li className={`block py-1.5 px-2 ${darkMode ? hoverTxtRedColor : hoverTxtBlueColor} ${darkMode ? hoverBGDarkColor : hoverBGWhiteGrayColor }`}>Domestic Routes</li>
                                </Link>
                            </div>
                        </ul>
                    </li>
                </ul>
            </div>
        </>
    );

}

export default SideNavbar;