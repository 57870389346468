import { Link } from "react-router-dom";
import { useContext, useEffect } from 'react';
import { NavContext, ThemeContext } from '../../App';
import { handleHover, handleHoverLeave } from "../../functions/cardAnimation";
import { bgBlueColor, bgGrayColor, bgRedColor, txtDarkColor, txtGrayColor } from "../../style/colors";

const AtcSoftware = () => {
    const setActiveNav = useContext(NavContext).setActiveNav;
    const darkMode = useContext(ThemeContext).darkMode;

    // set global variable for active page
    useEffect(() => {
        setActiveNav('atc');
    }, []);

    return(
        <div className="flex justify-center items-center pb-10">
            <div className={`w-screen sm:w-full py-5 px-10 ${darkMode ? txtGrayColor : txtDarkColor}`}>
                <h1 className={`text-center text-3xl font-bold pt-4 pb-2 ${darkMode ? txtGrayColor : txtDarkColor}`}>ATC Software</h1>
                <div className="flex justify-center items-center mb-5"><div className={`w-32 h-0.5 ${darkMode ? bgRedColor : bgBlueColor}`}></div></div>
                <section className="w-full">
                    <div className="w-full flex flex-wrap justify-center items-start gap-5">
                        <div onMouseEnter={() => handleHover(".software-img", 0)} onMouseLeave={() => handleHoverLeave(".software-img", 0)} className={`card-container relative flex flex-col justify-start items-center w-full rounded min-w-96 max-w-96 p-5 overflow-hidden ${darkMode ? 'shadow-sm' : 'shadow-lg'} shadow-gray-400 ${darkMode ? 'hover:shadow-gray-300' : 'hover:shadow-md'} transition duration-500 ease-in-out`}>
                            <div className="flex justify-center items-center">
                                <Link to="https://ivao.aero/softdev/software/aurora.asp" target="_blank"><img className="software-img w-80 transition duration-700 ease-in-out" src="/images/atc/aurora.webp" alt="altitude"></img></Link>
                            </div>
                            <Link to="https://ivao.aero/softdev/software/aurora.asp" target="_blank"><h1 className={`text-2xl font-bold pt-6 pb-2 ${darkMode ? txtGrayColor : txtDarkColor}`}>ATC Client - Aurora</h1></Link>
                            <div className="min-h-28 mb-20">
                                <h3 className="text-sm">Aurora, your new ATC client, is the first client in many years developed in house, it will allow us to seamlessly customize and adapt the software to offer you the best and most realistic ATC experience. New features will continue being developed and released.</h3>
                            </div>
                            <div className="absolute bottom-0 w-full flex justify-center py-6">
                                <Link to="https://ivao.aero/softdev/software/aurora.asp" target="_blank" ><button className={`text-lg font-bold focus:ring-4 focus:outline-none rounded-md flex justify-center items-center hover:scale-[101%] hover:text-white transition duration-100 px-10 py-1.5 ${txtGrayColor} ${darkMode ? bgGrayColor : bgBlueColor} ${darkMode ? '' : 'shadow-md shadow-gray-600/40'}`}>download</button></Link>
                            </div>
                        </div>
                        <div onMouseEnter={() => handleHover(".software-img", 1)} onMouseLeave={() => handleHoverLeave(".software-img", 1)} className={`card-container relative flex flex-col justify-start items-center w-full rounded min-w-96 max-w-96 p-5 overflow-hidden ${darkMode ? 'shadow-sm' : 'shadow-lg'} shadow-gray-400 ${darkMode ? 'hover:shadow-gray-300' : 'hover:shadow-md'} transition duration-500 ease-in-out`}>
                            <div className="flex justify-center items-center">
                                <Link to="https://ivao.aero/softdev/software/artifice.asp" target="_blank"><img className="software-img w-80 transition duration-700 ease-in-out" src="/images/atc/artifice.webp" alt="altitude"></img></Link>
                            </div>
                            <Link to="https://ivao.aero/softdev/software/artifice.asp" target="_blank"><h1 className={`text-2xl font-bold pt-6 pb-2 ${darkMode ? txtGrayColor : txtDarkColor}`}>Connector - Artifice</h1></Link>
                            <div className="min-h-28 mb-20">
                                <h3 className="text-sm">Artifice, this is your new connector. if you enjoy controlling using your flight simulator, Artifice will allow you the most realistic air traffic control experience. It allows you to connect as many softwares as you want into one IVAO connection.</h3>
                            </div>
                            <div className="absolute bottom-0 w-full flex justify-center py-6">
                                <Link to="https://ivao.aero/softdev/software/artifice.asp" target="_blank" ><button className={`text-lg font-bold focus:ring-4 focus:outline-none rounded-md flex justify-center items-center hover:scale-[101%] hover:text-white transition duration-100 px-10 py-1.5 ${txtGrayColor} ${darkMode ? bgGrayColor : bgBlueColor} ${darkMode ? '' : 'shadow-md shadow-gray-600/40'}`}>download</button></Link>
                            </div>
                        </div>
                        {/* <div onMouseEnter={() => handleHover(".software-img", 2)} onMouseLeave={() => handleHoverLeave(".software-img", 2)} className={`card-container relative flex flex-col justify-start items-center w-full rounded min-w-96 max-w-96 p-5 overflow-hidden ${darkMode ? 'shadow-sm' : 'shadow-lg'} shadow-gray-400 ${darkMode ? 'hover:shadow-gray-300' : 'hover:shadow-md'} transition duration-500 ease-in-out`}>
                            <div className="flex justify-center items-center">
                                <Link to="https://www.ivao.aero/softdev/ivac.asp" target="_blank"><img className="software-img w-80 transition duration-700 ease-in-out" src="/images/atc/ivac.webp" alt="IvAc"></img></Link>
                            </div>
                            <Link to="https://www.ivao.aero/softdev/ivac.asp" target="_blank"><h1 className={`text-2xl font-bold pt-6 pb-2 ${darkMode ? txtGrayColor : txtDarkColor}`}>IVAO ATC Client</h1></Link>
                            <div className="min-h-28 sm:min-h-40 mb-20">
                                <h3 className="text-sm">The IVAO ATC Client, known as IVAC, is a stand-alone program specifically developed for IVAO, based on the real radars of Belgocontrol, Eurocontrol and Amsterdam Radar, which allows you to control on the network.</h3>
                                <div className={`text-xs ${txtRedColor}`}>
                                    <h3>IvAc is deprecated and no longer recommended to use!</h3>
                                    <h3>IvAc is deprecated and replaced by our new ATC client <Link className={`${txtLightBlueColor} hover:underline`} to='https://ivao.aero/softdev/software/aurora.asp' target='_blank'>Aurora.</Link></h3>
                                    <h3>We strongly recommend using Aurora, as IvAc does not support the latest features on the network.</h3>
                                </div>
                            </div>
                            <div className="absolute bottom-0 w-full flex justify-center py-6">
                                <Link to="https://www.ivao.aero/softdev/ivac.asp" target="_blank" ><button className={`text-lg font-bold focus:ring-4 focus:outline-none rounded-md flex justify-center items-center hover:scale-[101%] hover:text-white transition duration-100 px-10 py-1.5 ${txtGrayColor} ${darkMode ? bgGrayColor : bgBlueColor} ${darkMode ? '' : 'shadow-md shadow-gray-600/40'}`}>download</button></Link>
                            </div>
                        </div> */}
                    </div>
                </section>
            </div>
        </div>
    );
}

export default AtcSoftware;