import { useContext, useEffect } from 'react';
import { NavContext, ThemeContext } from '../../App';
import { bgGrayColor700, bgRedColor, bgBlueColor, borderDarkColor, borderGrayColor, txtDarkColor, txtGrayColor, txtBlueColor } from "../../style/colors";

const AtcStations = () => {

    const setActiveNav = useContext(NavContext).setActiveNav;
    const darkMode = useContext(ThemeContext).darkMode;

    // set global variable for active page
    useEffect(() => {
        setActiveNav('atc');
    }, []);

    return(
        <div className="flex justify-center content-center pb-10">
            <div className={`w-screen sm:w-full py-5 px-10 ${darkMode ? txtGrayColor : txtDarkColor}`}>
                <section className="w-full flex justify-center content-center">
                <div className="w-full sm:w-8/12 md:w-6/12 flex flex-col justify-center content-center">
                        <h1 className="text-center text-xl md:text-2xl font-bold pt-6 pb-2">ATC Stations</h1>
                        <div className="flex justify-center content-center mb-5"><div className={`w-16 h-0.5 ${darkMode ? bgRedColor : bgBlueColor}`}></div></div>
                        <div className={`flex flex-col justify-center content-center text-sm sm:text-base border rounded shadow-md shadow-gray-600/40 ${darkMode ? borderGrayColor : borderDarkColor}`}>
                        <h1 className="text-center sm:text-lg lg:text-xl font-bold py-2">HECC FIR ATC Stations</h1>
                        <table className="table">
                                <thead>
                                    <tr className={`text-lg font-bold ${txtGrayColor} ${bgGrayColor700}`}>
                                        <th className={`border-e ${borderGrayColor}`}>ATC</th>
                                        <th className="">Frequency</th>
                                    </tr>
                                </thead>
                                <tbody className="text-center font-bold">
                                    <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HECC_CTR</td>
                                        <td className={`text-base ${darkMode ? txtGrayColor : txtBlueColor}`}>129.400</td>
                                    </tr>
                                    <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HECC_R1_CTR</td>
                                        <td className={`text-base ${darkMode ? txtGrayColor : txtBlueColor}`}>127.700</td>
                                    </tr>
                                    <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HECC_R2_CTR</td>
                                        <td className={`text-base ${darkMode ? txtGrayColor : txtBlueColor}`}>124.700</td>
                                    </tr>
                                    <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HECC_R3_CTR</td>
                                        <td className={`text-base ${darkMode ? txtGrayColor : txtBlueColor}`}>126.600</td>
                                    </tr>
                                    <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HECC_R4_CTR</td>
                                        <td className={`text-base ${darkMode ? txtGrayColor : txtBlueColor}`}>132.200</td>
                                    </tr>
                                    <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HECC_R5_CTR</td>
                                        <td className={`text-base ${darkMode ? txtGrayColor : txtBlueColor}`}>130.900</td>
                                    </tr>
                                    <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HECC_R6_CTR</td>
                                        <td className={`text-base ${darkMode ? txtGrayColor : txtBlueColor}`}>124.575</td>
                                    </tr>
                                    <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HECC_R12_CTR</td>
                                        <td className={`text-base ${darkMode ? txtGrayColor : txtBlueColor}`}>127.700</td>
                                    </tr>
                                    <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HECC_R34_CTR</td>
                                        <td className={`text-base ${darkMode ? txtGrayColor : txtBlueColor}`}>126.600</td>
                                    </tr>
                                    <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HECC_R56_CTR</td>
                                        <td className={`text-base ${darkMode ? txtGrayColor : txtBlueColor}`}>130.900</td>
                                    </tr>
                                    <tr className={`h-8 border-t ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HECC_C_CTR</td>
                                        <td className={`text-base ${darkMode ? txtGrayColor : txtBlueColor}`}>125.300</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={`flex flex-col justify-center content-center text-sm md:text-base xl:text-lg mt-6 border rounded shadow-md shadow-gray-600/40 ${darkMode ? borderGrayColor : borderDarkColor}`}>
                        <h1 className="text-center sm:text-lg lg:text-xl font-bold py-2">Cairo INTL Airport</h1>
                        <table className="table">
                                <thead>
                                    <tr className={`text-lg font-bold ${txtGrayColor} ${bgGrayColor700}`}>
                                        <th className={`border-e ${borderGrayColor}`}>ATC</th>
                                        <th className="">Frequency</th>
                                    </tr>
                                </thead>
                                <tbody className="text-center font-bold">
                                    <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HECA_DEL</td>
                                        <td className={`text-base ${darkMode ? txtGrayColor : txtBlueColor}`}>120.100</td>
                                    </tr>
                                    <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HECA_S_GND</td>
                                        <td className={`text-base ${darkMode ? txtGrayColor : txtBlueColor}`}>120.400</td>
                                    </tr>
                                    <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HECA_N_GND</td>
                                        <td className={`text-base ${darkMode ? txtGrayColor : txtBlueColor}`}>121.900</td>
                                    </tr>
                                    <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HECA_GND</td>
                                        <td className={`text-base ${darkMode ? txtGrayColor : txtBlueColor}`}>120.400</td>
                                    </tr>
                                    <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HECA_TWR</td>
                                        <td className={`text-base ${darkMode ? txtGrayColor : txtBlueColor}`}>118.100</td>
                                    </tr>
                                    <tr className={`h-8 border-t ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HECA_D_APP</td>
                                        <td className={`text-base ${darkMode ? txtGrayColor : txtBlueColor}`}>119.050</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={`flex flex-col justify-center content-center text-sm md:text-base xl:text-lg mt-6 border rounded shadow-md shadow-gray-600/40 ${darkMode ? borderGrayColor : borderDarkColor}`}>
                        <h1 className="text-center sm:text-lg lg:text-xl font-bold py-2">Borg El Arab INTL</h1>
                        <table className="table">
                                <thead>
                                    <tr className={`text-lg font-bold ${txtGrayColor} ${bgGrayColor700}`}>
                                        <th className={`border-e ${borderGrayColor}`}>ATC</th>
                                        <th className="">Frequency</th>
                                    </tr>
                                </thead>
                                <tbody className="text-center font-bold">
                                    <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HEBA_GND</td>
                                        <td className={`text-base ${darkMode ? txtGrayColor : txtBlueColor}`}>121.900</td>
                                    </tr>
                                    <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HEBA_TWR</td>
                                        <td className={`text-base ${darkMode ? txtGrayColor : txtBlueColor}`}>119.100</td>
                                    </tr>
                                    <tr className={`h-8 border-t ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HEBA_APP</td>
                                        <td className={`text-base ${darkMode ? txtGrayColor : txtBlueColor}`}>122.300</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={`flex flex-col justify-center content-center text-sm md:text-base xl:text-lg mt-6 border rounded shadow-md shadow-gray-600/40 ${darkMode ? borderGrayColor : borderDarkColor}`}>
                        <h1 className="text-center sm:text-lg lg:text-xl font-bold py-2">Sharm El Sheikh INTL</h1>
                        <table className="table">
                                <thead>
                                    <tr className={`text-lg font-bold ${txtGrayColor} ${bgGrayColor700}`}>
                                        <th className={`border-e ${borderGrayColor}`}>ATC</th>
                                        <th className="">Frequency</th>
                                    </tr>
                                </thead>
                                <tbody className="text-center font-bold">
                                    <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HESH_GND</td>
                                        <td className={`text-base ${darkMode ? txtGrayColor : txtBlueColor}`}>121.900</td>
                                    </tr>
                                    <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HESH_TWR</td>
                                        <td className={`text-base ${darkMode ? txtGrayColor : txtBlueColor}`}>118.900</td>
                                    </tr>
                                    <tr className={`h-8 border-t ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HESH_APP</td>
                                        <td className={`text-base ${darkMode ? txtGrayColor : txtBlueColor}`}>121.100</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={`flex flex-col justify-center content-center text-sm md:text-base xl:text-lg mt-6 border rounded shadow-md shadow-gray-600/40 ${darkMode ? borderGrayColor : borderDarkColor}`}>
                        <h1 className="text-center sm:text-lg lg:text-xl font-bold py-2">Hurghada INTL</h1>
                        <table className="table">
                                <thead>
                                    <tr className={`text-lg font-bold ${txtGrayColor} ${bgGrayColor700}`}>
                                        <th className={`border-e ${borderGrayColor}`}>ATC</th>
                                        <th className="">Frequency</th>
                                    </tr>
                                </thead>
                                <tbody className="text-center font-bold">
                                    <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HEGN_DEL</td>
                                        <td className={`text-base ${darkMode ? txtGrayColor : txtBlueColor}`}>118.225</td>
                                    </tr>
                                    <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HEGN_GND</td>
                                        <td className={`text-base ${darkMode ? txtGrayColor : txtBlueColor}`}>121.900</td>
                                    </tr>
                                    <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HEGN_TWR</td>
                                        <td className={`text-base ${darkMode ? txtGrayColor : txtBlueColor}`}>119.600</td>
                                    </tr>
                                    <tr className={`h-8 border-t ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>HEGN_APP</td>
                                        <td className={`text-base ${darkMode ? txtGrayColor : txtBlueColor}`}>123.400</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default AtcStations;