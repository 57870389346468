import { Link } from "react-router-dom";
import { useContext, useEffect } from 'react';
import { NavContext, ThemeContext } from '../../App';
import { txtDarkColor, txtLightBlueColor, borderGrayColor, borderDarkColor, bgGrayColor, bgRedColor, bgBlueColor, txtRedColor, bgGrayColor700, txtGrayColor, txtWhiteColor } from '../../style/colors';

const Staff = () => {
    const setActiveNav = useContext(NavContext).setActiveNav;
    const darkMode = useContext(ThemeContext).darkMode;

    // set global variable for active page
    useEffect(() => {
        setActiveNav('about');
    }, []);

    return(
        <div className="flex justify-center content-center pb-10">
            <div className={`w-screen sm:w-full py-5 px-10 ${darkMode ? txtGrayColor : txtDarkColor}`}>
                <section className="w-full flex flex-col justify-center content-center mb-5">
                    <h1 className="text-center text-xl md:text-2xl font-bold pt-6 pb-2">EG Staff</h1>
                    <div className="flex justify-center content-center mb-5"><div className={`w-16 h-0.5 ${darkMode ? bgRedColor : bgBlueColor}`}></div></div>
                    <div className={`w-full flex flex-col justify-center content-center border-2 rounded shadow-md shadow-gray-600/40 ${darkMode ? borderGrayColor : borderDarkColor}`}>
                        <table className="table-auto text-center lg:text-start">
                            <thead>
                                <tr className={`${txtWhiteColor} h-10 text-center text-base font-bold`}>
                                    <td className={`border-e ${darkMode ? bgGrayColor : bgBlueColor} ${borderGrayColor}`}>Callsign</td>
                                    <td className={`w-28 border-e ${darkMode ? bgGrayColor : bgBlueColor} ${borderGrayColor}`}>Badge</td>
                                    <td className={`border-e ${darkMode ? bgGrayColor : bgBlueColor} ${borderGrayColor}`}>Position</td>
                                    <td className={`border-e ${darkMode ? bgGrayColor : bgBlueColor} ${borderGrayColor}`}>Name</td>
                                    <td className={`${darkMode ? bgGrayColor : bgBlueColor}`}>E-mail</td>
                                </tr>
                            </thead>
                            <tbody className="text-xs sm:text-sm">
                                <tr className={`h-10 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                    <td className={`text-center border-e ${darkMode ? borderGrayColor : borderDarkColor}`}><Link to="#">EG-DIR</Link></td>
                                    <td className="h-full"><div className="flex justify-center content-center"><img className="w-9 sm:w-12 border-0" src="./images/badge/-DIR.gif" alt="DIR-badge" /><img className="w-9 sm:w-12 border-0" src="./images/badge/EG.gif" alt="EG-badge" /></div></td>
                                    <td className={`border-x lg:ps-6 ${darkMode ? borderGrayColor : borderDarkColor}`}>Director</td>
                                    <td className={`border-e lg:ps-6 ${darkMode ? borderGrayColor : borderDarkColor}`}>Samir Carali</td>
                                    <td className={`lg:ps-6 ${darkMode ? txtRedColor : txtLightBlueColor}`}><Link to="mailto:eg-dir@ivao.aero">eg-dir@ivao.aero</Link></td>
                                </tr>
                                        
                                <tr className={`h-10 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>   
                                    <td className={`text-center border-e ${darkMode ? borderGrayColor : borderDarkColor}`}><Link to="#">EG-ADIR</Link></td>
                                    <td className="h-full"><div className="flex justify-center content-center"><img className="w-9 sm:w-12 border-0" src="./images/badge/-ADIR.gif" alt="ADIR-badge" /><img className="w-9 sm:w-12 border-0" src="./images/badge/EG.gif" alt="EG-badge" /></div></td>
                                    <td className={`border-x lg:ps-6 ${darkMode ? borderGrayColor : borderDarkColor}`}>Assistant Director</td>
                                    <td className={`border-e lg:ps-6 ${darkMode ? borderGrayColor : borderDarkColor}`}>Mohammed Ramadan</td>
                                    <td className={`lg:ps-6 ${darkMode ? txtRedColor : txtLightBlueColor}`}><Link to="mailto:eg-adir@ivao.aero">eg-adir@ivao.aero</Link></td>
                                </tr>
                                        
                                <tr className={`h-6 text-center font-bold border-y ${txtWhiteColor} ${darkMode ? bgGrayColor700 : bgBlueColor} ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                    <td colSpan="7">Operational Departments</td>
                                </tr>
                                
                                <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>   
                                    <td className={`text-center border-e ${darkMode ? borderGrayColor : borderDarkColor}`}><Link to="#">EG-SOC</Link></td>
                                    <td className="h-full"><div className="flex justify-center content-center"><img className="w-9 sm:w-12 border-0" src="./images/badge/-SOC.gif" alt="SOC-badge" /><img className="w-9 sm:w-12 border-0" src="./images/badge/EG.gif" alt="EG-badge" /></div></td>
                                    <td className={`border-x lg:ps-6 ${darkMode ? borderGrayColor : borderDarkColor}`}>Special Operations Coordinator</td>
                                    <td className={`border-e lg:ps-6 ${darkMode ? borderGrayColor : borderDarkColor}`}>Amr Mohamed</td>
                                    <td className={`lg:ps-6 ${darkMode ? txtRedColor : txtLightBlueColor}`}><Link to="mailto:eg-soc@ivao.aero">eg-soc@ivao.aero</Link></td>
                                </tr>
                                
                                <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                    <td className={`text-center border-e ${darkMode ? borderGrayColor : borderDarkColor}`}><Link to="#">EG-FOC</Link></td>
                                    <td className="h-full"><div className="flex justify-center content-center"><img className="w-9 sm:w-12 border-0" src="./images/badge/-FOC.gif" alt="FOC-badge" /><img className="w-9 sm:w-12 border-0" src="./images/badge/EG.gif" alt="EG-badge" /></div></td>
                                    <td className={`border-x lg:ps-6 ${darkMode ? borderGrayColor : borderDarkColor}`}>Flight Operations Coordinator</td>
                                    <td className={`border-e lg:ps-6 ${darkMode ? borderGrayColor : borderDarkColor}`}>Ahmed Helal(on trial)</td>
                                    <td className={`lg:ps-6 ${darkMode ? txtRedColor : txtLightBlueColor}`}><Link to="mailto:eg-foc@ivao.aero">eg-foc@ivao.aero</Link></td>
                                </tr>
                                
                                <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                    <td className={`text-center border-e ${darkMode ? borderGrayColor : borderDarkColor}`}><Link to="#">EG-AOC</Link></td>
                                    <td className="h-full"><div className="flex justify-center content-center"><img className="w-9 sm:w-12 border-0" src="./images/badge/-AOC.gif" alt="AOC-badge" /><img className="w-9 sm:w-12 border-0" src="./images/badge/EG.gif" alt="EG-badge" /></div></td>
                                    <td className={`border-x lg:ps-6 ${darkMode ? borderGrayColor : borderDarkColor}`}>ATC Operations Coordinator</td>
                                    <td className={`border-e lg:ps-6 ${darkMode ? borderGrayColor : borderDarkColor}`}>Ahmed Hashem</td>
                                    <td className={`lg:ps-6 ${darkMode ? txtRedColor : txtLightBlueColor}`}><Link to="mailto:eg-aoc@ivao.aero">eg-aoc@ivao.aero</Link></td>
                                </tr>
                                
                                <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                    <td className={`text-center border-e ${darkMode ? borderGrayColor : borderDarkColor}`}><Link to="#">EG-AOAC</Link></td>
                                    <td className="h-full"><div className="flex justify-center content-center"><img className="w-9 sm:w-12 border-0" src="./images/badge/-AOAC.gif" alt="AOAC-badge" /><img className="w-9 sm:w-12 border-0" src="./images/badge/EG.gif" alt="EG-badge" /></div></td>
                                    <td className={`border-x lg:ps-6 ${darkMode ? borderGrayColor : borderDarkColor}`}>ATC Operations Assistant Coordinator</td>
                                    <td className={`border-e lg:ps-6 ${darkMode ? borderGrayColor : borderDarkColor}`}>Mohammad Sleem</td>
                                    <td className={`lg:ps-6 ${darkMode ? txtRedColor : txtLightBlueColor}`}><Link to="mailto:eg-aoac@ivao.aero">eg-aoac@ivao.aero</Link></td>
                                </tr>
                                
                                <tr className={`h-6 text-center font-bold border-y ${txtWhiteColor} ${darkMode ? bgGrayColor700 : bgBlueColor} ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                    <td colSpan="7">Training Department</td>
                                </tr>
                                
                                <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                    <td className={`text-center border-e ${darkMode ? borderGrayColor : borderDarkColor}`}><Link to="#">EG-TC</Link></td>  
                                    <td className="h-full"><div className="flex justify-center content-center"><img className="w-9 sm:w-12 border-0" src="./images/badge/-TC.gif" alt="TC-badge" /><img className="w-9 sm:w-12 border-0" src="./images/badge/EG.gif" alt="EG-badge" /></div></td>
                                    <td className={`border-x lg:ps-6 ${darkMode ? borderGrayColor : borderDarkColor}`}>Training Coordinator</td>
                                    <td className={`border-e lg:ps-6 ${darkMode ? borderGrayColor : borderDarkColor}`}>Mohammad Sleem</td>
                                    <td className={`lg:ps-6 ${darkMode ? txtRedColor : txtLightBlueColor}`}><Link to="mailto:eg-tc@ivao.aero">eg-tc@ivao.aero</Link></td>
                                </tr>

                                <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                    <td className={`text-center border-e ${darkMode ? borderGrayColor : borderDarkColor}`}><Link to="#">EG-TA1</Link></td>  
                                    <td className="h-full"><div className="flex justify-center content-center"><img className="w-9 sm:w-12 border-0" src="./images/badge/-TA1.gif" alt="TA1-badge" /><img className="w-9 sm:w-12 border-0" src="./images/badge/EG.gif" alt="EG-badge" /></div></td>
                                    <td className={`border-x lg:ps-6 ${darkMode ? borderGrayColor : borderDarkColor}`}>Division Training Advisor 1</td>
                                    <td className={`border-e lg:ps-6 ${darkMode ? borderGrayColor : borderDarkColor}`}>Ahmed Helal(on trial)</td>
                                    <td className={`lg:ps-6 ${darkMode ? txtRedColor : txtLightBlueColor}`}><Link to="mailto:eg-tc@ivao.aero">eg-ta1@ivao.aero</Link></td>
                                </tr>
                                                    
                                <tr className={`h-6 text-center font-bold border-y ${txtWhiteColor} ${darkMode ? bgGrayColor700 : bgBlueColor} ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                    <td colSpan="7">Membership Department</td>
                                </tr>
                                
                                <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                    <td className={`text-center border-e ${darkMode ? borderGrayColor : borderDarkColor}`}><Link to="#">EG-MC</Link></td>
                                    <td className="h-full"><div className="flex justify-center content-center"><img className="w-9 sm:w-12 border-0" src="./images/badge/-MC.gif" alt="MC-badge" /><img className="w-9 sm:w-12 border-0" src="./images/badge/EG.gif" alt="EG-badge" /></div></td>
                                    <td className={`border-x lg:ps-6 ${darkMode ? borderGrayColor : borderDarkColor}`}>Membership Coordinator</td>
                                    <td className={`border-e lg:ps-6 ${darkMode ? borderGrayColor : borderDarkColor}`}>Samir Carali</td>
                                    <td className={`lg:ps-6 ${darkMode ? txtRedColor : txtLightBlueColor}`}><Link to="mailto:eg-mc@ivao.aero">eg-mc@ivao.aero</Link></td>
                                </tr>
                                
                                <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                    <td className={`text-center border-e ${darkMode ? borderGrayColor : borderDarkColor}`}><Link to="#">EG-MAC</Link></td>
                                    <td className="h-full"><div className="flex justify-center content-center"><img className="w-9 sm:w-12 border-0" src="./images/badge/-MAC.gif" alt="MAC-badge" /><img className="w-9 sm:w-12 border-0" src="./images/badge/EG.gif" alt="EG-badge" /></div></td>
                                    <td className={`border-x lg:ps-6 ${darkMode ? borderGrayColor : borderDarkColor}`}>Membership Assistant Coordinator</td>
                                    <td className={`border-e lg:ps-6 ${darkMode ? borderGrayColor : borderDarkColor}`}>Ahmed Hashem</td>
                                    <td className={`lg:ps-6 ${darkMode ? txtRedColor : txtLightBlueColor}`}><Link to="mailto:eg-mac@ivao.aero">eg-mac@ivao.aero</Link></td>
                                </tr>
                                
                                <tr className={`h-6 text-center font-bold border-y ${txtWhiteColor} ${darkMode ? bgGrayColor700 : bgBlueColor} ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                    <td colSpan="7">Events Department</td>
                                </tr>
                                
                                <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                    <td className={`text-center border-e ${darkMode ? borderGrayColor : borderDarkColor}`}><Link to="#">EG-EC</Link></td>
                                    <td className="h-full"><div className="flex justify-center content-center"><img className="w-9 sm:w-12 border-0" src="./images/badge/-EC.gif" alt="EC-badge" /><img className="w-9 sm:w-12 border-0" src="./images/badge/EG.gif" alt="EG-badge" /></div></td>
                                    <td className={`border-x lg:ps-6 ${darkMode ? borderGrayColor : borderDarkColor}`}>Event Coordinator</td>
                                    <td className={`border-e lg:ps-6 ${darkMode ? borderGrayColor : borderDarkColor}`}>Zeyad Reda</td>
                                    <td className={`lg:ps-6 ${darkMode ? txtRedColor : txtLightBlueColor}`}><Link to="mailto:eg-ec@ivao.aero">eg-ec@ivao.aero</Link></td>
                                </tr>

                                <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                    <td className={`text-center border-e ${darkMode ? borderGrayColor : borderDarkColor}`}><Link to="#">EG-EAC</Link></td>
                                    <td className="h-full"><div className="flex justify-center content-center"><img className="w-9 sm:w-12 border-0" src="./images/badge/-EAC.gif" alt="EAC-badge" /><img className="w-9 sm:w-12 border-0" src="./images/badge/EG.gif" alt="EG-badge" /></div></td>
                                    <td className={`border-x lg:ps-6 ${darkMode ? borderGrayColor : borderDarkColor}`}>Event Assistant Coordinator</td>
                                    <td className={`border-e lg:ps-6 ${darkMode ? borderGrayColor : borderDarkColor}`}>Mohamed Hamed(on trial)</td>
                                    <td className={`lg:ps-6 ${darkMode ? txtRedColor : txtLightBlueColor}`}><Link to="mailto:eg-eac@ivao.aero">eg-eac@ivao.aero</Link></td>
                                </tr>
                                
                                <tr className={`h-6 text-center font-bold border-y ${txtWhiteColor} ${darkMode ? bgGrayColor700 : bgBlueColor} ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                    <td colSpan="7">Public Relations Department</td>
                                </tr>
                                
                                <tr className={`h-6 text-center font-bold border-y ${txtWhiteColor} ${darkMode ? bgGrayColor700 : bgBlueColor} ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                    <td colSpan="7">Web Development Department</td>
                                </tr>
                                
                                <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                    <td className={`text-center border-e ${darkMode ? borderGrayColor : borderDarkColor}`}><Link to="#">EG-WM</Link></td>
                                    <td className="h-full"><div className="flex justify-center content-center"><img className="w-9 sm:w-12 border-0" src="./images/badge/-WM.gif" alt="WM-badge" /><img className="w-9 sm:w-12 border-0" src="./images/badge/EG.gif" alt="EG-badge" /></div></td>
                                    <td className={`border-x lg:ps-6 ${darkMode ? borderGrayColor : borderDarkColor}`}>Webmaster</td>
                                    <td className={`border-e lg:ps-6 ${darkMode ? borderGrayColor : borderDarkColor}`}>Mohammed Ramadan</td>
                                    <td className={`lg:ps-6 ${darkMode ? txtRedColor : txtLightBlueColor}`}><Link to="mailto:eg-wm@ivao.aero">eg-wm@ivao.aero</Link></td>
                                </tr>
                                
                                <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                    <td className={`text-center border-e ${darkMode ? borderGrayColor : borderDarkColor}`}><Link to="#">EG-AWM</Link></td>
                                    <td className="h-full"><div className="flex justify-center content-center"><img className="w-9 sm:w-12 border-0" src="./images/badge/-AWM.gif" alt="AWM-badge" /><img className="w-9 sm:w-12 border-0" src="./images/badge/EG.gif" alt="EG-badge" /></div></td>
                                    <td className={`border-x lg:ps-6 ${darkMode ? borderGrayColor : borderDarkColor}`}>Assistant Webmaster</td>
                                    <td className={`border-e lg:ps-6 ${darkMode ? borderGrayColor : borderDarkColor}`}>Hassan Mohamed</td>
                                    <td className={`lg:ps-6 ${darkMode ? txtRedColor : txtLightBlueColor}`}><Link to="mailto:eg-awm@ivao.aero">eg-awm@ivao.aero</Link></td>
                                </tr>
                                
                                <tr className={`h-6 text-center font-bold border-y ${txtWhiteColor} ${darkMode ? bgGrayColor700 : bgBlueColor} ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                    <td colSpan="7">FIR Chiefs and Service Teams</td>
                                </tr>
                                
                                <tr className={`h-8 border-t ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                    <td className={`text-center border-e ${darkMode ? borderGrayColor : borderDarkColor}`}><Link to="#">HECC-CH</Link></td>								
                                    <td className="h-full"><div className="flex justify-center content-center"><img className="w-9 sm:w-12 border-0" src="./images/badge/-CH.gif" alt="CH-badge" /><img className="w-9 sm:w-12 border-0" src="./images/badge/EG.gif" alt="EG-badge" /></div></td>
                                    <td className={`border-x lg:ps-6 ${darkMode ? borderGrayColor : borderDarkColor}`}>Cairo FIR Egypt Chief</td>
                                    <td className={`border-e lg:ps-6 ${darkMode ? borderGrayColor : borderDarkColor}`}>Mohamed Salamoni</td>
                                    <td className={`lg:ps-6 ${darkMode ? txtRedColor : txtLightBlueColor}`}><Link to="mailto:hecc-ch@ivao.aero">hecc-ch@ivao.aero</Link></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </section>
            </div>
        </div>
    );

}

export default Staff;