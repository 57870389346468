import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { useContext, useEffect } from "react";
import Topbar from "./topbar";
import { NavContext } from "../../App";
import { bgDarkColor, bgWhiteColor, bgBlueColor, bgRedColor, txtDarkColor, txtGrayColor, txtBlueColor, txtRedColor, borderGrayColor, hoverBGGrayColor, hoverBGWhiteGrayColor, hoverTxtBlueColor, hoverTxtRedColor, hoverTxtGrayColor } from "../../style/colors";
import IvaoEgLogo from "../ivaoEgLogo";
import DarkMode from "./darkMode";

const Navbar = (props) => {

    const {darkMode, setDarkMode} = props.data;
    const activeNav = useContext(NavContext).activeNav;

    // give dropMenu class display none in first render
    useEffect(() => {
        closeAllDropMenu();
    },[]);

    // close all navbar's dropmenu function
    function closeAllDropMenu(){
        const dropMenus = document.querySelectorAll(".dropMenu");
            for(let i=0; i< dropMenus.length; i++){
                dropMenus[i].style.display = "none";
            }
    }

    // open-close dropmenus on hover
    function handleDropMenuHover(x){
        const dropMenus = document.querySelectorAll(".dropMenu");
        // show&hide Navbar drop menu on Hover
        if(x && window.innerWidth >= 768){
            if(dropMenus[x-1].style.display === "none"){
                // closeAllDropMenu();
                dropMenus[x-1].style.display = "block";
            }else{
                setTimeout(() => {
                    dropMenus[x-1].style.display = "none";
                },70);
            }
        }
    }

    // open-close dropmenus on click
    function handleDropMenuClick(x){
        const dropMenus = document.querySelectorAll(".dropMenu");
        // show&hide Navbar drop menu on Click
        if(x && window.innerWidth >= 768){
            if(dropMenus[x-1].style.display === "none"){
                closeAllDropMenu();
                dropMenus[x-1].style.display = "block";
            }
            else{
                closeAllDropMenu();
            }
        }
    }

    // scroll to top function when choosing a page
    function handleChoosenLink(){
        window.scrollTo({
            top:0,
            behavior: 'smooth',
        });
    }

    return(
        <div className={`${darkMode ? bgDarkColor : bgWhiteColor} ${darkMode ? 'shadow-sm shadow-gray-800' : 'shadow-sm shadow-gray-300' } nav-header w-full z-40 hidden md:block sticky top-0 select-none transition duration-500 ease-in-out`}>
            <Topbar data={{darkMode}} />
            <div className="flex justify-center content-center">
                <nav className="relative w-full h-20 max-w-screen-xl flex flex-row justify-center py-4">
                    <div className="absolute h-full top-0 left-0 flex justify-center items-center">
                        <div className="logoContainer ps-3 xl:ps-0">
                            <Link onClick={() => {handleChoosenLink()}} to="/">
                                <IvaoEgLogo data={{darkMode}} />
                            </Link>
                        </div>
                    </div>
                    <ul className="menuItems flex flex-row items-center space-x-6 md:space-x-8 lg:space-x-14 xl:space-x-24 ms-32">
                        <li onClick={() => {handleChoosenLink()}} className={`${darkMode ? txtGrayColor : txtDarkColor} py-1 lg:pb-1 xl:pb-0`}>
                            <Link className={`${activeNav === 'home' ? (darkMode ? txtRedColor : txtBlueColor) : ''} ${darkMode ? hoverTxtRedColor : hoverTxtBlueColor} font-bold uppercase md:text-xs lg:text-base p-1.5`} to="/">Home</Link>
                        </li>
                        <li onMouseEnter={() => handleDropMenuHover(1)} onMouseLeave={() => handleDropMenuHover(1)} className={`${darkMode ? txtGrayColor : txtDarkColor} py-1 lg:pb-1 xl:pb-0`}>
                            <Link onClick={() => {handleChoosenLink(); handleDropMenuClick(1);}} className={`${activeNav === 'pilots' ? (darkMode ? txtRedColor : txtBlueColor) : ''} ${darkMode ? hoverTxtRedColor : hoverTxtBlueColor} font-bold uppercase md:text-xs lg:text-base p-1.5`} to="#">Pilots<span className="ms-2.5 text-xs"><FontAwesomeIcon icon={faCaretDown} /></span></Link>
                            <ul className="relative cursor-pointer">
                                <div className={`${darkMode ? bgDarkColor : bgWhiteColor} dropMenu w-48 absolute pt-7 xl:pt-6`}>
                                    <div className={`w-full h-0.5 ${darkMode ? bgRedColor : bgBlueColor}`}></div>
                                    <div className={`shadow ${darkMode ? 'shadow-gray-700' : 'shadow-gray-600/40'}`}>
                                        <Link onClick={() => {handleChoosenLink()}} to="/pilot-training-exams">
                                            <li className={`block py-1.5 px-2 border-b ${borderGrayColor} ${darkMode ? hoverTxtGrayColor : hoverTxtBlueColor} ${darkMode ? hoverBGGrayColor : hoverBGWhiteGrayColor}`}>Pilot Training/Exams</li>
                                        </Link>
                                        <Link onClick={() => {handleChoosenLink()}} to="/pilot-software">
                                            <li className={`block py-1.5 px-2 border-b ${borderGrayColor} ${darkMode ? hoverTxtGrayColor : hoverTxtBlueColor} ${darkMode ? hoverBGGrayColor : hoverBGWhiteGrayColor}`}>Pilot Software</li>
                                        </Link>
                                        <Link onClick={() => {handleChoosenLink()}} to="/guidelines-ifr">
                                            <li className={`block py-1.5 px-2 border-b ${borderGrayColor} ${darkMode ? hoverTxtGrayColor : hoverTxtBlueColor} ${darkMode ? hoverBGGrayColor : hoverBGWhiteGrayColor}`}>Guidelines IFR</li>
                                        </Link>
                                        <Link onClick={() => {handleChoosenLink()}} to="/guidelines-vfr">
                                            <li className={`block py-1.5 px-2 border-b ${borderGrayColor} ${darkMode ? hoverTxtGrayColor : hoverTxtBlueColor} ${darkMode ? hoverBGGrayColor : hoverBGWhiteGrayColor}`}>Guidelines VFR</li>
                                        </Link>
                                        <Link onClick={() => {handleChoosenLink()}} to="https://wiki.ivao.aero/en/home/flightoperations/FAQ_VA" target="_blank">
                                            <li className={`block py-1.5 px-2 border-b ${borderGrayColor} ${darkMode ? hoverTxtGrayColor : hoverTxtBlueColor} ${darkMode ? hoverBGGrayColor : hoverBGWhiteGrayColor}`}>Virtual Airlines - FAQ</li>
                                        </Link>
                                        {/* <Link onClick={() => {handleChoosenLink()}} to="/eg-training-docs">
                                            <li className={`block py-1.5 px-2 ${darkMode ? hoverTxtGrayColor : hoverTxtBlueColor} ${darkMode ? hoverBGGrayColor : hoverBGWhiteGrayColor}`}>EG Documentation</li>
                                        </Link> */}
                                    </div>
                                </div>
                            </ul>
                        </li>
                        <li onMouseEnter={() => handleDropMenuHover(2)} onMouseLeave={() => handleDropMenuHover(2)} className={`${darkMode ? txtGrayColor : txtDarkColor} py-1 lg:pb-1 xl:pb-0`}>
                            <Link onClick={() => {handleChoosenLink(); handleDropMenuClick(2);}} className={`${activeNav === 'atc' ? (darkMode ? txtRedColor : txtBlueColor) : ''} ${darkMode ? hoverTxtRedColor : hoverTxtBlueColor} font-bold uppercase md:text-xs lg:text-base p-1.5"`} to="#">ATC<span className="ms-2.5 text-xs"><FontAwesomeIcon icon={faCaretDown} /></span></Link>
                            <ul className="relative cursor-pointer">
                                <div className={`${darkMode ? bgDarkColor : bgWhiteColor} dropMenu w-44 absolute pt-7 xl:pt-6`}>
                                    <div className={`w-full h-0.5 ${darkMode ? bgRedColor : bgBlueColor}`}></div>
                                    <div className={`shadow ${darkMode ? 'shadow-gray-700' : 'shadow-gray-600/40'}`}>
                                        <Link onClick={() => {handleChoosenLink()}} to="/atc-training-exams">
                                            <li className={`block py-1.5 px-2 border-b ${borderGrayColor} ${darkMode ? hoverTxtGrayColor : hoverTxtBlueColor} ${darkMode ? hoverBGGrayColor : hoverBGWhiteGrayColor}`}>ATC Training/Exams</li>
                                        </Link> 
                                        <Link onClick={() => {handleChoosenLink()}} to="/atc-software">
                                            <li className={`block py-1.5 px-2 border-b ${borderGrayColor} ${darkMode ? hoverTxtGrayColor : hoverTxtBlueColor} ${darkMode ? hoverBGGrayColor : hoverBGWhiteGrayColor}`}>ATC Software</li>
                                        </Link> 
                                        <Link onClick={() => {handleChoosenLink()}} to="/atc-stations">
                                            <li className={`block py-1.5 px-2 border-b ${borderGrayColor} ${darkMode ? hoverTxtGrayColor : hoverTxtBlueColor} ${darkMode ? hoverBGGrayColor : hoverBGWhiteGrayColor}`}>ATC Stations</li>
                                        </Link>
                                        <Link onClick={() => {handleChoosenLink()}} to="/ssr-codes">
                                            <li className={`block py-1.5 px-2 border-b ${borderGrayColor} ${darkMode ? hoverTxtGrayColor : hoverTxtBlueColor} ${darkMode ? hoverBGGrayColor : hoverBGWhiteGrayColor}`}>SSR Codes</li>
                                        </Link>
                                        <Link onClick={() => {handleChoosenLink()}} to="/atc-documents">
                                            <li className={`block py-1.5 px-2 ${darkMode ? hoverTxtGrayColor : hoverTxtBlueColor} ${darkMode ? hoverBGGrayColor : hoverBGWhiteGrayColor}`}>Documents</li>
                                        </Link>
                                    </div>
                                </div>
                            </ul>
                        </li>
                        <li onMouseEnter={() => handleDropMenuHover(3)} onMouseLeave={() => handleDropMenuHover(3)} className={`${darkMode ? txtGrayColor : txtDarkColor} py-1 lg:pb-1 xl:pb-0`}>
                            <Link onClick={() => {handleDropMenuClick(3)}} className={`${activeNav === 'about' ? (darkMode ? txtRedColor : txtBlueColor) : ''} ${darkMode ? hoverTxtRedColor : hoverTxtBlueColor} font-bold uppercase md:text-xs lg:text-base p-1.5`} to="#">About<span className="ms-2 text-xs"><FontAwesomeIcon icon={faCaretDown} /></span></Link>
                            <ul className="relative cursor-pointer">
                                <div className={`${darkMode ? bgDarkColor : bgWhiteColor} dropMenu w-44 absolute pt-7 xl:pt-6`}>
                                    <div className={`w-full h-0.5 ${darkMode ? bgRedColor : bgBlueColor}`}></div>
                                    <div className={`shadow ${darkMode ? 'shadow-gray-700' : 'shadow-gray-600/40'}`}>
                                        <Link onClick={handleChoosenLink} to="https://wiki.ivao.aero/en/home/ivao/information" target="_blank">
                                            <li className={`block py-1.5 px-2 border-b ${borderGrayColor} ${darkMode ? hoverTxtGrayColor : hoverTxtBlueColor} ${darkMode ? hoverBGGrayColor : hoverBGWhiteGrayColor}`}>Rules & Regulations</li>
                                        </Link> 
                                        <Link onClick={() => {handleChoosenLink()}} to="/staff">
                                            <li className={`block py-1.5 px-2 ${darkMode ? hoverTxtGrayColor : hoverTxtBlueColor} ${darkMode ? hoverBGGrayColor : hoverBGWhiteGrayColor}`}>EG Staff</li>
                                        </Link>
                                    </div>
                                </div>
                            </ul>
                        </li>
                        <li onMouseEnter={() => handleDropMenuHover(4)} onMouseLeave={() => handleDropMenuHover(4)} className={`${darkMode ? txtGrayColor : txtDarkColor} py-1 lg:pb-1 xl:pb-0`}>
                            <Link onClick={() => {handleDropMenuClick(4)}} className={`${activeNav === 'resources' ? (darkMode ? txtRedColor : txtBlueColor) : ''} ${darkMode ? hoverTxtRedColor : hoverTxtBlueColor} font-bold uppercase md:text-xs lg:text-base p-1.5`} to="#">Resources<span className="ms-2.5 text-xs"><FontAwesomeIcon icon={faCaretDown} /></span></Link>
                            <ul className="relative cursor-pointer">
                                <div className={`${darkMode ? bgDarkColor : bgWhiteColor} dropMenu w-44 absolute pt-7 xl:pt-6`}>
                                    <div className={`w-full h-0.5 ${darkMode ? bgRedColor : bgBlueColor}`}></div>
                                    <div className={`shadow ${darkMode ? 'shadow-gray-700' : 'shadow-gray-600/40'}`}>
                                        <Link onClick={handleChoosenLink} to="https://eg.ivao.aero/download/data/EG_Division_procedure_V1.1.pdf" target="_blank">
                                            <li className={`block py-1.5 px-2 border-b ${borderGrayColor} ${darkMode ? hoverTxtGrayColor : hoverTxtBlueColor} ${darkMode ? hoverBGGrayColor : hoverBGWhiteGrayColor}`}>Division Procedure</li>
                                        </Link> 
                                        <Link onClick={() => {handleChoosenLink()}} to="/gca-requirements">
                                            <li className={`block py-1.5 px-2 border-b ${borderGrayColor} ${darkMode ? hoverTxtGrayColor : hoverTxtBlueColor} ${darkMode ? hoverBGGrayColor : hoverBGWhiteGrayColor}`}>(GCA) Requirements</li>
                                        </Link>
                                        <Link onClick={() => {handleChoosenLink()}} to="/charts">
                                            <li className={`block py-1.5 px-2 border-b ${borderGrayColor} ${darkMode ? hoverTxtGrayColor : hoverTxtBlueColor} ${darkMode ? hoverBGGrayColor : hoverBGWhiteGrayColor}`}>Charts</li>
                                        </Link>
                                        <Link onClick={handleChoosenLink} to="https://eg.ivao.aero/download/data/EG_Domestic_Routes_2313.pdf" target="_blank">
                                            <li className={`block py-1.5 px-2 ${darkMode ? hoverTxtGrayColor : hoverTxtBlueColor} ${darkMode ? hoverBGGrayColor : hoverBGWhiteGrayColor}`}>Domestic Routes</li>
                                        </Link>
                                    </div>
                                </div>
                            </ul>
                        </li>
                        <li className="absolute right-0 z-40 flex justify-center items-center pe-3 xl:pe-0">
                            <DarkMode data={{darkMode, setDarkMode}}/>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );

}

export default Navbar;