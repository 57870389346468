import { useContext, useEffect } from 'react';
import { NavContext, ThemeContext } from '../../App';
import { bgGrayColor700, bgRedColor, bgBlueColor, borderDarkColor, borderGrayColor, txtDarkColor, txtGrayColor, txtBlueColor } from "../../style/colors";

const AtcSSR = () => {

    const setActiveNav = useContext(NavContext).setActiveNav;
    const darkMode = useContext(ThemeContext).darkMode;

    // set global variable for active page
    useEffect(() => {
        setActiveNav('atc');
    }, []);

    return(
        <div className="flex justify-center content-center pb-10">
            <div className={`w-screen sm:w-full py-5 px-0 ${darkMode ? txtGrayColor : txtDarkColor}`}>
                <section className="w-full flex justify-center content-center">
                <div className="w-full sm:w-8/12 lg:w-6/12 flex flex-col justify-center content-center mx-4">
                        <h1 className="text-center text-xl md:text-2xl font-bold pt-6 pb-2">SSR Codes</h1>
                        <div className="flex justify-center content-center mb-5"><div className={`w-16 h-0.5 ${darkMode ? bgRedColor : bgBlueColor}`}></div></div>
                        <div className={`flex flex-col justify-center content-center text-xs lg:text-base border rounded shadow-md shadow-gray-600/40 ${darkMode ? borderGrayColor : borderDarkColor}`}>
                        <table className="table">
                                <thead>
                                    <tr className={`text-lg font-bold ${txtGrayColor} ${bgGrayColor700}`}>
                                        <th className={`border-e ${borderGrayColor}`}>Airport</th>
                                        <th className="">SSR Codes</th>
                                    </tr>
                                </thead>
                                <tbody className="text-center font-bold">
                                    <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>International Departure</td>
                                        <td className={`md:text-base ${darkMode ? txtGrayColor : txtBlueColor}`}>3320 - 3347 / 7301 - 7377</td>
                                    </tr>
                                    <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>International Over flights</td>
                                        <td className={`md:text-base ${darkMode ? txtGrayColor : txtBlueColor}`}>1601 - 1677  / 3320 - 3377</td>
                                    </tr>
                                    <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>Domestic - Cairo Departure</td>
                                        <td className={`md:text-base ${darkMode ? txtGrayColor : txtBlueColor}`}>2701 - 2777</td>
                                    </tr>
                                    <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>Domestic - Cairo Arrival</td>
                                        <td className={`md:text-base ${darkMode ? txtGrayColor : txtBlueColor}`}>0601 - 0677</td>
                                    </tr>
                                    <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>Domestic - Hurghada Departure</td>
                                        <td className={`md:text-base ${darkMode ? txtGrayColor : txtBlueColor}`}>2701 - 2777 / 3701 – 3777</td>
                                    </tr>
                                    <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e text-xs md:md:text-base xl:text-lg ${darkMode ? borderGrayColor : borderDarkColor}`}>Domestic flights other than Cairo</td>
                                        <td className={`md:text-base ${darkMode ? txtGrayColor : txtBlueColor}`}>3701 - 3777 / 2310 - 2337</td>
                                    </tr>
                                    {/* <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>Hurghada Departure to Cairo</td>
                                        <td className={`md:text-base ${darkMode ? txtGrayColor : txtBlueColor}`}>2701 – 2777 / 0601 - 0677</td>
                                    </tr> */}
                                    <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>VFR</td>
                                        <td className={`md:text-base ${darkMode ? txtGrayColor : txtBlueColor}`}>7400 – 7437</td>
                                    </tr>
                                    <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>Uncontrolled flight</td>
                                        <td className={`md:text-base ${darkMode ? txtGrayColor : txtBlueColor}`}>7000</td>
                                    </tr>
                                    <tr className={`h-8 border-y ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>Radio Communication Failure</td>
                                        <td className={`md:text-base ${darkMode ? txtGrayColor : txtBlueColor}`}>7600</td>
                                    </tr>
                                    <tr className={`h-8 border-t ${darkMode ? borderGrayColor : borderDarkColor}`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>Emergency</td>
                                        <td className={`md:text-base ${darkMode ? txtGrayColor : txtBlueColor}`}>7700</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default AtcSSR;