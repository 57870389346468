import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../App';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompass, faXmark } from '@fortawesome/free-solid-svg-icons';
import { txtWhiteColor, txtGrayColor, bgDarkGrayColor, txtBlueColor, bgWhiteGrayColor, txtDarkColor, bgGrayColor700 } from "../style/colors";

const QuickLinks = () => {
    const darkMode = useContext(ThemeContext).darkMode;
    const [quickLinksMenu, setQuickLinksMenu] = useState(true);
    const [planeFinished, setPlaneFinished] = useState(false);

    useEffect(() => {
        planeAnimation();
    }, [quickLinksMenu]);
    useEffect(() => {
        if(planeFinished === true){
            setTimeout(() => {
                planeAnimation();
            }, 9000);
        }
    }, [planeFinished]);

    function scrollToTop(){
        window.scrollTo({
            top:0,
            behavior: 'smooth',
        });
    }
    function planeAnimation(){
        let plane = document.querySelector(".plane");
        let plane2 = document.querySelector(".plane2");
        let contrail = document.querySelector(".contrail");
        let contrail2 = document.querySelector(".contrail2");
        let planePath = document.querySelector(".plane-path");
        const planeInterval = setInterval(() => {
            if(planePath){
                let planePathWidth = planePath.getBoundingClientRect().width;
                let planeLocation = plane.getBoundingClientRect().right;
                let planeMovement = planePathWidth+80;
                // console.log(planeLocation >= planePathWidth+40);
                if(planeLocation >= planePathWidth+80){
                    plane.style.visibility="hidden";
                    contrail.style.visibility="hidden";
                    plane.style.transition = "transform 0s";
                    contrail.style.transition = "transform 0s";
                    plane.style.transform = "translateX(0px)";
                    contrail.style.transform = "translateX(0px)";

                    plane2.style.visibility="hidden";
                    contrail2.style.visibility="hidden";
                    plane2.style.transition = "transform 0s";
                    contrail2.style.transition = "transform 0s";
                    plane2.style.transform = "translateX(0px)";
                    contrail2.style.transform = "translateX(0px)";
                    setPlaneFinished(true);
                    clearInterval(planeInterval);
                }else{
                    setPlaneFinished(false);
                    plane.style.visibility="visible";
                    contrail.style.visibility="visible";
                    plane.style.transition = "transform 15s";
                    contrail.style.transition = "transform 15s";
                    plane.style.transform = "translateX("+planeMovement+"px)";
                    contrail.style.transform = "translateX("+planeMovement+"px)";

                    plane2.style.visibility="visible";
                    contrail2.style.visibility="visible";
                    plane2.style.transition = "transform 15s";
                    contrail2.style.transition = "transform 15s";
                    plane2.style.transform = "translateX(-"+planeMovement+"px)";
                    contrail2.style.transform = "translateX(-"+planeMovement+"px)";
                }   
            }
        }, 1000);
    }

    return(
        <>
            {quickLinksMenu && <div className={`plane-path w-full h-24 lg:h-14 relative flex flex-col justify-center items-center ${darkMode ? bgDarkGrayColor : bgWhiteGrayColor}`}>
                {/* <div className={`w-full h-0.5 absolute z-0 opacity-20 ${bgYellowColor}`}></div> */}
                <div className='absolute h-full w-full flex items-start overflow-hidden'>
                    <div className='relative h-full w-full flex'>
                        <img className='plane absolute left-0 w-10 drop-shadow-md -mt-1 -ms-10 z-30' src='./plane.png' alt="plane"></img>
                        <div className={`contrail absolute left-0 min-w-[1024px] max-w-screen h-0.5 bg-gradient-to-l ${darkMode ? 'from-gray-400' : 'from-[#0D2C99]'} mt-1.5 -ms-[1044px] z-20`}></div>
                    </div>
                </div>
                <div className='absolute h-full w-full flex items-end overflow-hidden'>
                    <div className='relative h-full w-full flex'>
                        <img className='plane2 absolute right-0 bottom-0 w-10 drop-shadow-md mb-[1px] -me-10 z-30' src='./plane2.png' alt="plane"></img>
                        <div className={`contrail2 absolute right-0 bottom-0 min-w-[1024px] max-w-screen h-0.5 bg-gradient-to-r ${darkMode ? 'from-gray-400' : 'from-[#0D2C99]'} mb-1.5 -me-[1044px] z-20`}></div>
                    </div>
                </div>
                <ul className={`flex flex-row justify-center items-center flex-wrap gap-y-1.5 sm:gap-y-2 space-x-1 sm:space-x-3 text-[8px] sm:text-[10px] md:text-xs px-4 sm:px-6 md:px-8 z-20 ${darkMode ? txtWhiteColor : txtBlueColor}`}>
                    <li className='hover:scale-105 ease-in-out duration-200'>
                        <Link className={`flex justify-center items-center p-2 rounded`} to="https://ivao.aero/" target="_blank">
                            {darkMode ? <img className='w-9 xs:w-10 sm:w-11 md:w-12 overflow-hidden' src='ivao-white.svg' alt="ivao"></img> : <img className='w-9 xs:w-10 sm:w-11 md:w-12 overflow-hidden' src='ivao.svg' alt="ivao"></img>}
                        </Link>
                    </li>
                    <li className='hover:scale-105 ease-in-out duration-200'>
                        <Link className={`flex justify-center items-center p-2 rounded`} to="https://webeye.ivao.aero/" target="_blank">IVAO Webeye</Link>
                    </li>
                    <li className='hover:scale-105 ease-in-out duration-200'>
                        <Link className={`flex justify-center items-center p-2 rounded`} to="https://fpl.ivao.aero/" target="_blank">IVAO Flight Plan</Link>
                    </li>
                    <li className='hover:scale-105 ease-in-out duration-200'>
                        <Link className={`flex justify-center items-center p-2 rounded`} to="https://tracker.ivao.aero/" target="_blank">IVAO Tracker</Link>
                    </li>
                    <li className='hover:scale-105 ease-in-out duration-200'>
                        <Link className={`flex justify-center items-center p-2 rounded`} to="https://wt.ivao.aero/" target="_blank">World Tour System</Link>
                    </li>
                    <li className='hover:scale-105 ease-in-out duration-200'>
                        <Link className={`flex justify-center items-center p-2 rounded`} to="https://tours.th.ivao.aero/index.php?div=EG" target="_blank">IVAO Egypt Tours</Link>
                    </li>
                    <li className='hover:scale-105 ease-in-out duration-200'>
                        <Link className={`flex justify-center items-center p-2 rounded`} to="https://wiki.ivao.aero" target="_blank">IVAO Wiki</Link>
                    </li>
                    <li className='hover:scale-105 ease-in-out duration-200'>
                        <Link className={`flex justify-center items-center p-2 rounded`} to="https://forum.ivao.aero/" target="_blank">IVAO Forum</Link>
                    </li>
                    <li className='hidden xl:block hover:scale-105 ease-in-out duration-200' onClick={() => navigator.clipboard.writeText('https://discord.gg/cr5qB7pUFP')}>
                        <Link className={`flex justify-center items-center p-2 rounded`} to="https://discord.gg/cr5qB7pUFP" target="_blank">
                            {darkMode ? <img className='w-4 sm:w-5 overflow-hidden me-1' src='icons-social/icon-discord-white.svg' alt='discord'></img> : <img className='w-4 sm:w-5 overflow-hidden me-1' src='icons-social/icon-discord-active.svg' alt='discord'></img> }
                            Join our Discord
                        </Link>
                    </li>
                </ul>
                <div onClick={() => setQuickLinksMenu(false)} className='absolute h-full flex flex-col justify-center items-center right-0 cursor-pointer hover:scale-110 p-2 mx-1 z-30'><FontAwesomeIcon className={`text-xl ${darkMode ? txtWhiteColor : txtDarkColor}`} icon={faXmark} /></div>
            </div>}
            <div className='fixed top-36 right-3 z-40 flex flex-col justify-center items-center opacity-30 2xl:opacity-100'>
                {!quickLinksMenu && <div onClick={() => {setQuickLinksMenu(true); scrollToTop();}} className={`flex flex-col justify-center items-center cursor-pointer p-1 ${darkMode ? txtGrayColor : txtBlueColor}`}><div className={`h-full w-full absolute rounded blur-lg ${darkMode ? bgGrayColor700 : bgWhiteGrayColor}`}></div><FontAwesomeIcon className={`text-3xl animate-[spin_5s_linear_infinite] hover:animate-[spin_1s_linear_infinite]`} icon={faCompass} /><h2 className='hidden 2xl:block text-xs font-bold mt-1 select-none animate-pulse'>Quick Links</h2></div>}
                {/* <div className='w-20 h-10 flex justify-center items-center bg-[#7289da] rounded mt-6'><h3 className='text-sm'>Discord</h3></div> */}
            </div>
        </>
    );
}

export default QuickLinks;