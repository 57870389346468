import { useContext } from 'react';
import { Link } from "react-router-dom";
import { ThemeContext } from '../App';
import { bgDarkColor, bgGrayColor, bgIVAOGrayColor, txtBlueColor, txtDarkColor, txtGrayColor, txtRedColor } from "../style/colors";
import IvaoEgLogo from "./ivaoEgLogo";

const Footer = () => {
    const darkMode = useContext(ThemeContext).darkMode;

    // scroll to top function when choosing a page
    function handleChoosenLink(){
        window.scrollTo({
            top:0,
            behavior: 'smooth',
        });
    }

    return(
        <footer className={`w-full static absolute bottom-0 z-40 p-2 transition duration-500 ease-in-out ${darkMode ? bgIVAOGrayColor : bgDarkColor} ${darkMode ? txtDarkColor : txtGrayColor}`}>
            <div className='flex flex-col justify-center items-center'>
                <div className="w-full max-w-screen-xl flex flex-col sm:flex-row justify-between items-start ps-10 p-3 sm:p-3">
                    <div>
                        <Link onClick={() => {handleChoosenLink()}} to="/">
                            <IvaoEgLogo data={{darkMode}} type={"footer"} />
                        </Link>
                    </div>
                    <ul className="py-4 sm:py-0"><span className={`${darkMode ? txtBlueColor : txtRedColor}`}>IVAO EG</span>
                        <li className='text-sm pt-1 hover:underline'>
                            <Link onClick={() => {handleChoosenLink()}} to="https://eg.ivao.aero/download/data/EG_Division_procedure_V1.1.pdf" target="_blank">Division Procedure</Link>
                        </li>
                        <li className='text-sm hover:underline'>
                            <Link onClick={() => {handleChoosenLink()}} to="/charts">Charts</Link>
                        </li>
                        <li className='text-sm hover:underline'>
                            <Link onClick={() => {handleChoosenLink()}} to="/pilot-training-exams">Pilot Training/Exams</Link>
                        </li>
                        <li className='text-sm hover:underline'>
                            <Link onClick={() => {handleChoosenLink()}} to="/atc-training-exams">ATC Training/Exams</Link>
                        </li>
                    </ul>
                    <ul className='py-2 sm:py-0'><span className={`${darkMode ? txtBlueColor : txtRedColor}`}>Quick Links</span>
                        <li className='text-sm pt-1 hover:underline'>
                            <Link to="https://webeye.ivao.aero/" target="_blank">IVAO Webeye</Link>
                        </li>
                        <li className='text-sm hover:underline'>
                            <Link to="https://fpl.ivao.aero/" target="_blank">IVAO Flight Plan</Link>
                        </li>
                        <li className='text-sm hover:underline'>
                            <Link to="https://wiki.ivao.aero" target="_blank">IVAO Wiki</Link>
                        </li>
                        <li className='text-sm hover:underline'>
                            <Link to="https://forum.ivao.aero/" target="_blank">IVAO Forum</Link>
                        </li>
                    </ul>
                    <ul className='py-2 sm:py-0'><span className={`${darkMode ? txtBlueColor : txtRedColor}`}>About us</span>
                        <li className='text-sm pt-1 hover:underline'>
                            <Link onClick={() => {handleChoosenLink()}} to="/staff">EG Staff</Link>
                        </li>
                        <li className='text-sm hover:underline'>
                            <Link to="https://wiki.ivao.aero/en/home/ivao/information" target="_blank">Rules & Regulations</Link>
                        </li>
                        <li className='text-sm hover:underline' onClick={() => navigator.clipboard.writeText('https://discord.gg/cr5qB7pUFP')}>
                            <Link to="https://discord.gg/cr5qB7pUFP" target="_blank">Join our Discord</Link>
                        </li>
                    </ul>
                </div>
                <div className={`h-0.5 w-full ${bgGrayColor}`}></div>
                <div className="flex justify-center items-center pt-2">
                    <h2>2025 © IVAO Egypt. All Rights Reserved.</h2>
                </div>
            </div>
        </footer>
    );

}

export default Footer;